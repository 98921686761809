// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~daterangepicker/daterangepicker.css";
@import "~select2/dist/css/select2.min.css";
@import "node_modules/select2/src/scss/core";
@import "node_modules/select2-bootstrap-5-theme/src/include-all";

// Extra
@import "global";
@import "style";
@import "responsive";
