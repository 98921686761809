a {
    text-decoration: none;
}

.flex {
    display: flex;
}

.btn-primary {
    background-color: $color-main;
    border-color: $color-main;
}

.bold {
    font-weight: bold;
}

.link, .btn-link {
    color: $color-main;
}

.input-group {
    .input-group-text {
        background-color: #fff;
    }
}

.blue {
    color: $color-main;
}

.dark-grey {
    color: $color-dark-grey;
}

.dark {
    color: $color-dark;
}

.light-dark {
    color: $color-light-dark;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}

.yellow {
    color: $color-yellow;
}

.background-green {
    background: lighten($color-green, 35);
}

.background-red {
    background: lighten($color-red, 35);
}

.background-yellow {
    background: lighten($color-yellow, 35);
}

.feedback {
    color: #212529;
    font-size: 0.875em;
    margin-top: 0.25rem;
    width: 100%;
}

.file-drop-area {
    align-items: center;
    border: 1px dashed $color-grey;
    border-radius: 0.25rem;
    display: flex;
    max-width: 100%;
    padding: 25px;
    position: relative;
    transition: 0.2s;
    width: 100%;
}

.file-message {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.g-recaptcha {
    width: auto;
}

.cursor {
    cursor: pointer;
}

.pull-left {
    float: left;
    width: auto;
}

.pull-right {
    float: right;
    width: auto;
}

.pagination {
    flex-wrap: wrap !important;
    margin-bottom: 0;
}

.btn-yellow {
    background: $color-yellow;
    color: #fff;

    &:hover {
        background: darken($color-yellow, 5%);
        color: #fff;
    }
}

.pointer {
    cursor: pointer;
}

.custom-spinner {
    font-size: 20px;
    height: 80px;
    width: 80px;
}

.pre-wrap {
    white-space: pre-wrap;
}

.p-10 {
    padding: 10px;
}