// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #337ab7;
$brand-info: #8eb4cb;
$brand-success: #5cb85c;
$brand-warning: #cbb956;
$brand-danger: #d9534f;

// Typography
$fa-font-path: "../webfonts";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

//Custom
$color-main: #337ab7;
$color-light-main: lighten($color-main, 40%);
$color-yellow: #fc0;
$color-light-grey: #f5f7fe;
$color-grey: #ddd;
$color-dark-grey: #252d38;
$color-orange: #d58512;
$color-dark: #212529;
$color-light-dark: lighten($color-dark, 20%);
$color-green: #5cb85c;
$color-red: #d9534f;