.navbar {
    background: $color-main;
    padding: 5px 0 10px;
}

.navbar-brand {
    padding: 0;

    img {
        max-height: 40px;
        width: auto;
    }
}

.main-content {
    min-height: calc(100vh - 300px);
    padding-top: 20px;
}

#navbarSupportedContent {
    align-items: center;
    justify-content: flex-end;

    .navbar-nav {
        li {
            a {
                box-shadow: none;
                color: #fff;
                font-size: 15px;
                font-weight: 600;
                padding: 10px 15px;
            }
        }

        &.navbar-right {
            margin-left: 30px;
        }

        .language-switcher {
            margin-top: -2px;

            .language-menu {
                left: auto;
                margin-top: 17px;
                right: 0;
            }

            a {
                .language-image {
                    height: 16px;
                    width: auto;
                }
            }

            ul {
                li {
                    text-align: center;

                    a {
                        color: $color-main;

                        .language-image {
                            height: 20px;
                            margin-left: 5px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    .account-dropdown {
        background: $color-main;
        color: #fff;
        left: auto;
        margin-top: 17px;
        min-width: 165px;
        right: 0;

        a {
            &:hover {
                background: #265c8b;
            }
        }
    }
}

.get-quotes {
    .create-ad {
        border: 1px solid #fff;
        border-radius: 7px;
        padding: 5px 20px;
    }
}

.login-register {
    background: $color-light-grey;
    margin: 30px auto;

    max-width: 500px;

    .card-header {
        background: $color-light-grey;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    .register-type-holder {
        display: flex;
        justify-content: space-between;

        .btn-register-type {
            border: 1px solid $color-grey;
            border-radius: 7px;
            cursor: pointer;
            padding: 15px 0;
            width: 49%;

            i {
                color: $color-light-dark;
                font-size: 30px;
            }

            &.register-type-selected {
                border: 2px solid $color-main;

                i {
                    color: $color-main;
                }
            }
        }
    }

    .terms {
        color: $color-main;
    }

    .register-inputs {
        margin-top: 20px;
    }
}

.static-page {
    padding-bottom: 40px;

    h1 {
        font-weight: bold;
        padding-bottom: 40px;
        padding-top: 50px;
        text-align: center;
    }

    h3 {
        font-weight: bold;
        padding-bottom: 5px;
        padding-top: 10px;
    }

    p {
        font-size: 12pt;
        margin-bottom: 0;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    ol, ul {
        padding-left: calc(var(--bs-gutter-x));

        li {
            font-size: 12pt;
            padding-bottom: 10px;
        }
    }

    table {
        margin-left: calc(var(--bs-gutter-x));
        padding-left: 0;

        tr {
            td {
                padding-bottom: 5px;
                padding-right: 20px;
                padding-top: 5px;
            }
        }

    }
}

#footer {
    background: $color-dark-grey;
    color: #ffffff;
    min-height: 245px;
    padding: 50px 0;
    text-align: center;

    h4 {
        color: $color-yellow;
        margin-top: 0;
    }

    .footer-menu {
        list-style: none;

        li {
            a {
                color: $color-grey;
                font-size: 14px;
                padding: 0;

                &:hover {
                    color: $color-yellow;
                }

                &.footer-link-special {
                    color: $color-orange;

                    &:hover {
                        color: $color-grey;
                    }
                }
            }
        }
    }
}

.welcome-banner {
    background: url("/img/asphalt.jpg") 50% 50%;
    min-height: 580px;

    .home-quote {
        color: #fff;
        padding-top: 60px;

        .home-quote-info {
            font-size: 12px;
        }
    }
}

.welcome-reasons {
    background: #fff;
    padding-bottom: 60px;
    padding-top: 60px;

    .features-stars {
        margin: 20px 0 0;
        position: relative;
    }

    .features-stars:before {
        background-image: linear-gradient(
                270deg,
                rgba(254, 161, 66, 0) 0,
                rgba(254, 161, 66, 20%) 10%,
                #fea142 50%,
                rgba(254, 161, 66, 20%) 90%,
                rgba(254, 161, 66, 0)
        );
        bottom: 11px;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        max-width: 500px;
        position: absolute;
        transform: translateX(-50%);
        width: 100%;
        z-index: 0;
    }

    .stars-wrapper {
        background: #fff;
        color: lighten($color-orange, 10%);
        margin: auto;
        padding: 0 20px;
        position: relative;
        width: fit-content;
        z-index: 1;
    }

    .reason-img {
        height: 64px;
        margin-bottom: 10px;
        margin-top: 30px;

        img {
            height: auto;
            max-height: 100%;
            max-width: 100%;
            width: auto;
        }
    }
}

.welcome-ship {
    padding-bottom: 60px;
    padding-top: 60px;

    .ship-info-holder {
        margin-top: 20px;

        .ship-img {
            img {
                height: auto;
                max-height: 100%;
                max-width: 100%;
                width: auto;
            }
        }

        .ship-info {
            padding-top: 60px;

            .icon-text-feature {
                color: #333;
                font-size: 18px;
                font-weight: bold;
                margin: 10px 0;

                i {
                    color: $color-yellow;
                }
            }
        }
    }
}

.page-subtitle {
    padding-bottom: 20px;
    padding-top: 30px;
}

.category-icon {
    color: $color-light-dark;
    display: block;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;

    &:hover {
        color: $color-main;
    }

    i {
        font-size: 40px;
    }
}

.post_info {
    font-size: small;

    a {
        font-size: small;
    }
}

.current-location {
    background: #fff;
    color: $color-dark;
    display: block;
    left: 3px;
    padding: 5px;
    position: absolute;
    text-align: center;
    top: 3px;
    width: 34px;

    i {
        font-size: 18px;
    }
}

.search-filter {
    border: 1px solid $color-grey;
    color: $color-dark;
    font-size: 14px;
}

.search-results {
    .search-info {
        font-size: 12px;
        font-weight: bold;
    }

    .quote-item {
        border: 1px solid $input-border;
        border-radius: 7px;
        padding: 7px 5px 5px;

        .quote-image {
            display: flex;
            justify-content: center;

            img {
                height: auto;
                max-height: 40px;
                max-width: 100%;
                width: auto;
            }

            i {
                color: $color-main;
                font-size: 36px;
            }
        }

        &:hover {
            background: $color-light-main;
        }
    }
}

.quote-map-holder {
    height: 300px;
    width: 100%;
}

.quote-title {
    font-size: 28px;
}

.quote-info-holder {
    .quote-image-holder {
        height: 200px;

        .image-pre-holder {
            border: 1px solid $color-grey;
            border-radius: 7px;
            height: 100%;
            overflow: hidden;
            width: 100%;

            i {
                color: $color-main;
                font-size: 100px;
                left: 50%;
                position: relative;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            #quoteImagesSlider {
                height: 100%;
                width: 100%;

                .carousel-inner {
                    height: 100%;
                    width: 100%;

                    .carousel-item {
                        height: 100%;
                        width: 100%;

                        img {
                            left: 50%;
                            min-height: 100%;
                            min-width: 100%;
                            position: relative;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}

.quote-user-holder {
    height: 200px;

    .user-pre-holder {
        background: $color-light-grey;
        border: 1px solid $color-grey;
        border-radius: 7px;
        height: 100%;
        overflow: hidden;
        padding: 20px;
        width: 100%;

        .username {
            font-size: 16px;
            text-align: center;
        }

        .rating {
            font-size: 14px;
            text-align: center;
        }
    }
}

.home-bottom-quote {
    background: $color-light-grey;
    border-radius: 7px;
    cursor: pointer;
    margin: 20px;
    overflow: hidden;
    padding-bottom: 5px;

    .bottom-quote-img {
        height: 150px;
        overflow: hidden;

        i {
            color: $color-main;
            font-size: 100px;
            left: 50%;
            position: relative;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: relative;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .bottom-quote-title {
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        overflow: hidden;
        padding: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bottom-quote-location {
        font-size: 14px;
        height: 33px;
        overflow: hidden;
        padding: 5px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bottom-quote-time {
        font-size: 14px;
        height: 33px;
        padding: 5px 10px;

        i {
            color: $color-light-dark;
        }
    }
}

#quoteImagesModal {
    #quoteImagesFullSlider {
        max-width: 100%;
        min-width: 480px;

        .carousel-inner {
            max-width: 100%;
            min-width: 480px;

            .carousel-item {
                max-width: 100%;
                min-width: 480px;

                img {
                    max-width: 100%;
                    min-width: 480px;
                }
            }
        }
    }
}

.quote-detail {
    line-height: 28px;
}

.quote-place-bid {
    border: 1px solid $color-grey;
    border-radius: 7px;
    overflow: hidden;
    padding: 20px;
}

.quote-bid-container {
    border: 1px solid $color-grey;
    border-radius: 7px;
    overflow: hidden;

    .bid-row {
        border-bottom: 1px solid $color-grey;
        margin-bottom: 0 !important;

        &:last-child {
            border-bottom: 0 none;
        }

        .bid-item-header {
            font-size: 12px;
            font-weight: bold;
            padding: 10px 0;
            text-align: center;
        }

        .bid-item {
            padding: 10px 0;
            text-align: center;
        }
    }

    .bid-info-row {
        border-bottom: 1px solid $color-grey;

        &:last-child {
            border-bottom: 0 none;
        }

        & > * {
            padding-left: calc(var(--bs-gutter-x) * 1);
            padding-right: calc(var(--bs-gutter-x) * 1);
        }

        .bid-message {
            white-space: pre-wrap;
        }
    }
}

.report-flag {
    color: lighten($color-red, 20);

    &:hover {
        color: $color-red;
    }
}

.card-account {
    padding: 20px;
}

.account-quote-holder {
    border: 1px solid $color-grey;
    border-radius: 7px;
    padding: 10px;
}

.customer-image-holder {
    position: relative;

    img {
        max-height: 200px;
        max-width: 100%;
    }

    .delete-image {
        color: $color-red;
        font-size: 24px;
        position: absolute;
        right: 10px;
        top: 0;

        &:hover {
            color: red;
        }
    }
}

.small-box {
    border-radius: 7px;
    color: #fff;
    position: relative;
    text-align: center;

    .inner {
        color: #fff;
        padding: 10px;

        h3 {
            color: #fff;
            font-size: 2.2rem;
            font-weight: 700;
            margin: 0 0 10px;
            padding: 0;
            white-space: nowrap;
        }
    }

    .icon {
        color: #00000026;

        i {
            font-size: 70px;
            position: absolute;
            right: 15px;
            top: 20px;
        }
    }

    .small-box-footer {
        background-color: #0000001a;
        color: #ffffffcc;
        display: block;
        padding: 3px 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        z-index: 10;

        &:hover {
            background-color: #00000026;
        }
    }
}

.pre-message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stars-holder {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .stars {
        direction: rtl;
        margin: 0 auto;

        label {
            cursor: pointer;
            margin: 0;
            padding: 10px;
        }

        .fa-solid, .fa-regular {
            color: #f1c40f;
            font-size: 30px;
        }

        label:hover > .fa-regular,
        label:hover > .fa-solid,
        label:hover ~ label > .fa-regular,
        label:hover ~ label > .fa-solid {
            color: $color-red;
        }

        input:not(:checked) ~ label:not([for="rating-1"]) > .fa-solid {
            display: none;
        }

        label:hover > .fa-regular,
        label:hover ~ label > .fa-regular,
        input:checked ~ label > .fa-regular {
            display: none;
        }

        label:hover > .fa-solid,
        label:hover ~ label > .fa-solid,
        input:checked ~ label > .fa-solid {
            display: inline-block !important;
        }
    }
}

.stars-show {
    .fa-solid, .fa-regular {
        color: #f1c40f;
        font-size: 30px;
    }

    &.stars-small {
        .fa-solid, .fa-regular {
            font-size: 14px;
        }
    }
}

.btn-messages {
    position: relative;

    span {
        background: $color-red;
        border-radius: 50%;
        color: #fff;
        display: block;
        height: 20px;
        line-height: 20px;
        position: absolute;
        right: -8px;
        text-align: center;
        top: -8px;
        width: 20px;
    }
}

.admin-image {
    max-width: 100%;
    max-height: 150px;
}