@media only screen and (max-width: 992px) {
    #navbarSupportedContent {
        background: #000000eb;
        filter: brightness(0.95);
        flex-direction: column;
        height: 94vh;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 55px;
        width: 100%;
        z-index: 9;

        .navbar-nav {
            align-items: center;
            display: flex;
            flex-direction: column;
            float: none;
            justify-content: center;
            margin-top: 50px;
            width: 100%;

            &.navbar-right {
                margin-left: 0;
                margin-top: 0;
            }

            li {
                display: block;
                margin: 12px 0;

                &.language-switcher {
                    margin: 12px 0;
                }
            }
        }
    }

    .welcome-ship {
        .ship-info-holder {
            .ship-info {
                padding-top: 20px;
            }
        }
    }

}

@media only screen and (max-width: 575px) {
    .m-lr-3 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }
}